<template>
  <div class="about-us">
    <div class="round-right"></div>
    <div class="round-left"></div>
    <div class="container">
      <div class="page-title w-100">
        Наши <span>продукты</span>
      </div>
      <div class="product-block-title">
        DJOON
      </div>

      <div class="product-block-desc">
        Мы создаем сайты, порталы, комплексные веб-системы любой сложности и системы автоматизации, мобильные приложения
      </div>
      <div class="product-block-img-1" :style="{ 'background-image': `url('./css/images/DJOON-1.png')` }">

      </div>

      <div class="product-block-desc">
        Мы создаем сайты, порталы, комплексные веб-системы любой сложности и системы автоматизации, мобильные приложения
      </div>
      <div class="product-block-img-2" :style="{ 'background-image': `url('./css/images/DJOON-2.png')` }">

      </div>

      <div class="product-block-title">
        RentMate
      </div>
      <div class="product-block-desc">
        Мы создаем сайты, порталы, комплексные веб-системы любой сложности и системы автоматизации, мобильные приложения
      </div>
      <div class="product-block-img-3" :style="{ 'background-image': `url('./css/images/RentMate-1.png')` }">

      </div>

      <div class="product-block-desc">
        Мы создаем сайты, порталы, комплексные веб-системы любой сложности и системы автоматизации, мобильные приложения
      </div>
      <div class="product-block-img-4" :style="{ 'background-image': `url('./css/images/RentMate-2.png')` }">

      </div>

      <div class="product-block-desc">
        Мы создаем сайты, порталы, комплексные веб-системы любой сложности и системы автоматизации, мобильные приложения
      </div>
      <div class="product-block-img-5" :style="{ 'background-image': `url('./css/images/RentMate-3.png')` }">

      </div>

    </div>
  </div>
</template>


<script>

import Vue from "vue";

export default {
  name: 'Products',
  data() {
    return {
      currentTab: "all",
    }
  },
}
</script>
<style>

</style>
